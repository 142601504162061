<template>
    <div>
        <v-simple-table
                class="mt-3 appic-table-light specification-table"
                dense
        >
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="grey lighten-2">{{ $t('message.event') }}</th>
                        <th class="grey lighten-2">{{ $t('message.remarks') }}</th>
                        <th class="grey lighten-2">{{ $t('message.mode') }}</th>
                        <th class="grey lighten-2">{{ $t('message.location') }}</th>
                        <th class="grey lighten-2">{{ $t('message.plannedDate') }}</th>
                        <th class="grey lighten-2">{{ $t('message.actualDate') }}</th>
                        <th class="grey lighten-2">{{ $t('message.delayed') + '?' }}</th>
                        <th class="grey lighten-2">{{ $t('message.recordDate') }}</th>
                    </tr>
                </thead>
                <tbody v-if="TrackingInfo.length > 0">
                    <tr v-for="(item,index) in TrackingInfo" :key="index">
                        <td>{{ item.Tracking.display_event }}</td>
                        <td>{{ item.Tracking.remarks }}</td>
                        <td><v-icon small color="grey">{{ item.Tracking.mode == 'TRUCK' ? 'fas fa-truck-moving' : 'fas fa-ship' }}</v-icon> {{ item.Tracking.mode }}</td>
                        <td>{{ item.Tracking.location }}</td>
                        <td>{{ item.Tracking.planned_date }}</td>
                        <td>{{ item.Tracking.actual_date }}</td>
                        <td>{{ item.Tracking.delayed == 1 ? $t('message.yes') : $t('message.no') }}</td>
                        <td>{{ item.Tracking.created }}</td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="8" class="font-weight-bold text-center">{{ $t('message.noTrackingInformationHere') }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
    import {mapMultiRowFields} from "vuex-map-fields";
    import {formatDate} from "Helpers/helpers";

    export default {
        name: "TrackingInfo",
        computed: {
            ...mapMultiRowFields('shipment',{
                TrackingInfo: 'current.TrackingInfo'
            }),
            formatDate: () => formatDate,
        }
    }
</script>

<style scoped>

</style>